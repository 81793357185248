import React, { useRef } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { compose, layout, position, space } from "styled-system"

import { Box, Flex, RatioContainer, Typography } from "components/common"
import { jewlr_t } from "helpers/application"
import { LinkButton } from "jewlr/pages/components"

const Container = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
`
const TextWrapper = styled(Box).attrs({
  contain: true,
})`
  position: absolute;
  height: 100%;
  width: 100%;
  ${(props) => props.theme.mediaQueries.tablet`
    cursor: pointer;
  `}
`
const TextBox = styled(Box).attrs(({ right, top, ...props }) => ({
  right: { desktop: "15%", tablet: "8%", widescreen: "14%", ...right },
  top: {
    _: "30px",
    desktop: "20px",
    tablet: "22%",
    widescreen: "18%",
    ...top,
  },
  ...props,
}))`
  position: absolute;
  text-align: center;
  top: 30px;
  width: 100%;
  ${(props) => props.theme.mediaQueries.tablet`
    width: auto
  `}

  ${compose(layout, position, space)}
`

const HomepageBanner = () => {
  const blockRef = useRef()
  const bannerLink = `/${jewlr_t("terms.personalized")}-${jewlr_t(
    "terms.jewelry"
  )}`

  return (
    <RatioContainer
      mb={{ _: "48px", tablet: 9 }}
      pb={{
        _: "131.68%",
        desktop: "27.6%",
        tablet: "52.7%",
      }}
    >
      <Flex justifyContent="center" position="relative">
        <Container>
          <Box
            as={Link}
            data-lc="home-banner"
            display="block"
            to={bannerLink}
            width="100%"
          >
            <picture>
              <source
                media="(max-width: 767px)"
                sizes="100vw"
                srcSet={`
                ${sl.config.jewlr_asset_url}images/homepage/jewlr/main_banner/mobile.jpg 767w,
                ${sl.config.jewlr_asset_url}images/homepage/jewlr/main_banner/mobile_2x.jpg 1534w,
              `}
              />
              <source
                media="(min-width: 768px) and (max-width: 1023px)"
                sizes="100vw"
                srcSet={`
                  ${sl.config.jewlr_asset_url}images/homepage/jewlr/main_banner/tablet.jpg 1005w,
                  ${sl.config.jewlr_asset_url}images/homepage/jewlr/main_banner/tablet_2x.jpg 2010w,
                `}
              />
              <source
                media="(min-width: 1024px)"
                sizes="100vw"
                srcSet={`
                ${sl.config.jewlr_asset_url}images/homepage/jewlr/main_banner/desktop.jpg 1920w,
                ${sl.config.jewlr_asset_url}images/homepage/jewlr/main_banner/desktop_2x.jpg 3840w,
              `}
              />
              <img
                alt=""
                src={`${sl.config.jewlr_asset_url}images/homepage/jewlr/main_banner/desktop.jpg`}
                width="100%"
              />
            </picture>
          </Box>
          <TextWrapper
            onClick={() => {
              if (blockRef.current) {
                blockRef.current.querySelector("a").click()
              }
            }}
            ref={blockRef}
          >
            <TextBox>
              <Box
                as={Link}
                data-lc="home-banner"
                display="block"
                to={bannerLink}
              >
                <Typography
                  as="h1"
                  color="black"
                  fontSize={{ _: 30, tablet: 34 }}
                  fontWeight="300"
                  mb={{ _: 1, tablet: 2 }}
                >
                  {jewlr_t("terms.Personalized")} {jewlr_t("terms.jewelry")}
                  ,
                  <br />
                  made just for you.
                </Typography>
                <Typography
                  color="black"
                  fontSize={{ _: 16, widescreen: 18 }}
                  light
                  mb={{ _: 2, widescreen: 4 }}
                  pb={1}
                >
                  <>
                    Fine {jewlr_t("terms.jewelry")} custom-made in just days
                    <br />
                    at a price you can afford. Create a
                    <br />
                    one-of-a-kind gift for you or someone you love.
                  </>
                </Typography>
              </Box>
              <LinkButton
                data-lc="home-banner"
                onClick={(e) => e.stopPropagation()}
                to={bannerLink}
              >
                Start {jewlr_t("terms.Personalizing")}
              </LinkButton>
            </TextBox>
          </TextWrapper>
        </Container>
      </Flex>
    </RatioContainer>
  )
}

export default HomepageBanner
