import { Box, Flex, Typography } from "@jewlr/storybook/core"
import { Link } from "react-router-dom"
import styled from "styled-components"

export const LinkBlock = styled(Box).attrs((props) => ({
  as: Link,
  display: "block",
  ...props,
}))``

export const GridBlock = styled(Flex).attrs((props) => ({
  alignItems: "flex-end",
  display: { _: "block", tablet: "flex" },
  gutter: { tablet: 2 },
  mb: { _: 6, tablet: 9 },
  ...props,
}))``

export const SectionTextBlock = styled(Box).attrs((props) => ({
  mb: 9,
  ml: { _: 4, tablet: 2 },
  mt: 3,
  ...props,
}))``
export const SectionTitle = styled(Typography).attrs((props) => ({
  as: "h2",
  color: "black",
  fontSize: 30,
  fontWeight: "300",
  mb: "10px",
  ...props,
}))``

export const SectionDescription = styled(Typography).attrs((props) => ({
  color: "black",
  fontSize: { _: "16px", tablet: "18px" },
  light: true,
  mb: "18px",
  ...props,
}))``

export const SectionLink = styled(Typography).attrs((props) => ({
  as: Link,
  ...props,
}))`
  color: ${(props) => props.theme.colors.black};
  font-size: 18px;
  font-weight: 300;
  text-decoration: underline;
  :hover,
  :active {
    text-decoration: none;
  }
`

export const LinkButton = styled(Box)
  .withConfig({
    shouldForwardProp: (prop) => prop != "lineHeight",
  })
  .attrs((props) => ({
    as: Link,
    bg: "white",
    border: "none",
    color: "black",
    display: "inline-block",
    fontSize: "18px",
    lineHeight: "16px",
    p: { _: "10px 26px", tablet: "16px 32px" },
    ...props,
  }))`
  box-shadow: ${(props) =>
    props.boxShadow || "2px 3px 6px -4px rgba(0, 0, 0, 0.55)"};
  border-radius: 0;
  line-height: 16px;
  :focus {
    opacity: 0.7;
    outline: none;
  }
`
